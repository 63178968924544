/*  ************************************
 *      Phone: landscape
 ************************************ */
@media all and (max-device-height : 767px) and (orientation: landscape) {
    .orientation-warning {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .orientation-warning p {
        width: 320px;
        font-size: 20px;
        text-align: center;
    }
}

/*  ************************************
 *      Phone: small
 ************************************ */
@media all and (max-device-width : 360px) and (orientation: portrait) {
    .landing-screen .product-name {
        font-size: 16px;
        line-height: 1em;
        margin-top: 40px;
        marging-bottom: 5px;
    }

    .landing-screen .label {
        font-size: 36px;
        line-height: 1em;
        margin-top: 30px;
        margin-bottom: 175px;
    }

    .section-title {
        font-size: 38px;
    }
}

/*  ************************************
 *      Phone
 ************************************ */
@media all and (max-device-width : 767px) and (orientation: portrait) {
    body {
        /*background: url("../assets/guides/guideSplash.png") no-repeat;*/
        /*background-size: cover;*/
    }

    .button-footer {
        flex-direction: column;
        flex-flow: column-reverse;
        justify-content: flex-start;
    }

    .orientation-warning {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .landing-screen {
        background: url("../assets/doorbell@1x.png") no-repeat;
        background-size: 130%;
        background-position-x: center;
        background-position-y: calc(50% + 30px);
    }

    .landing-screen .button-container {
        position: fixed;
    }

    .compatibility-checklist .section-title {
        font-size: 38px;
    }

    .checklist header {
        border-bottom: none;
    }

    .checklist-section-header {
        margin-left: 17px;
        border-top: 1px solid #00000020;
        font-size: 22px;
        /*border-bottom: 2px solid #00000010;*/
        margin-block-start: 0;
    }

    .tool-list .checklist-section-header:first-of-type {
        border-top: none;
    }

    .checklist ul {
        padding-inline-start: 17px;
        padding-inline-end: 24px;
    }

    .checklist input {
        margin-right: -20px;
    }

    .overview .section-title {
        /*border: 1px solid red;*/
        margin-left: 17px;
        margin-bottom: 22px;
    }

    .overview-container {
        margin-top: 32px;
        margin-left: 11px;
    }

    .modal-overlay .popup {
        /*width: 720px;*/
        /*height: 274px;*/
        width: inherit;
        height: inherit;
        margin: 0 11px;
        border-radius: 8px;
        padding: 10px;
    }

    .modal-overlay .help-text {
        margin: 0;
    }

    .modal-overlay .button-container {
        position: static;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin: 40px 0 0 0;
    }

    .modal-overlay .btn-continue, .modal-overlay .btn-start-over {
        width: 100%;
        font-size: 18px;
        margin-bottom: 10px;
    }

    .step .content ul {
        margin-block-end: 50px;
    }

}
