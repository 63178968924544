/*  ************************************
 *      App-wide
 ************************************ */

@font-face {
  font-family: BrownPro-Regular;
  font-weight: normal;
  src: url('../assets/fonts/BrownPro-Regular.otf') format('opentype');
}

@font-face {
  font-family: BrownPro-Bold;
  font-weight: bold;
  src: url('../assets/fonts/BrownPro-Bold.otf') format('opentype');
}

@font-face {
  font-family: BrownPro-Light;
  font-weight: 100;
  src: url('../assets/fonts/BrownPro-Light.otf') format('opentype');
}

body {
  margin: 0;
  font-family: BrownPro-Regular;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("../assets/bgTexture.png")repeat;
}


html {
  box-sizing: border-box ;
}

*, *:before, *:after {
  box-sizing: inherit ;
}


/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 7px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 4px;
}

.App {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  /*border: 1px solid green;*/
}

.App > .main-screen {
  transition: transform 0.3s;
}

.App.menu-visible > .main-screen {
  transform: translateX(-10vw);
}

.App > main {
  min-height: calc(100vh - 72px);
  border: 1px solid red;
}

.label {
  font-family: BrownPro-Bold;
  font-size: 20px;
  color: #000000;
  /*border: 1px solid red;*/
}

.label-warning {
  font-family: BrownPro-Bold;
  font-size: 20px;
  color: red;
  /*border: 1px solid red;*/
}

/*  ************************************
 *      Button styles
 ************************************ */

button {
  display: block;
  cursor: pointer;
  outline: none;
}

button:hover {
  color: #879a07;
  cursor: pointer;
}

button:active {
  opacity: 0.6;
}

.btn {
  background: #2F3132;
  border: 1px solid rgba(184,210,8,0.20);
  width: 100%;
  margin-bottom: 20px;
  height: 50px;
  font-family: BrownPro-Bold;
  font-size: 19px;
  color: #B8D208;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  text-transform: uppercase;
  pointer-events: auto;
}

.btn-primary, .btn-secondary, .btn-tertiary  {
  font-size: 16px;
  width: initial;
  height: initial;
  min-height: 44px;
  margin: 6px 3% 12px 3%;
  padding: 0 20px 0 20px;
}

.btn-primary:hover, .btn-primary:active {
  color: #B8D208;
  opacity: 0.8;
}

.btn-secondary {
  border: 1px solid #2F3132;
}

.btn-secondary, .btn-tertiary {
  background: transparent;
  color: #2F3132;
}

.btn-secondary:hover, .btn-tertiary:hover, .btn-secondary:active, .btn-tertiary:active {
  color: initial;
  background: rgba(0, 0,  0, 0.04);
  opacity: 1;
}

.btn-tertiary {
  border: none;
  outline: none;
}

.button-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  padding-top:100px;
}

.button-container {
  width: calc(100% - 44px);
  margin: 0 22px 0 22px;
}

.btn-advanced {
  background: transparent;
  color: #2F3132;
  border: none;
  outline: none;
}

.btn-start-over {
  font-size: 16px;
  background: transparent;
  color: #2F3132;
  border: none;
  outline: none;
}

.btn-start-over.hidden {
  visibility: hidden;
}

/*  ************************************
 *      Header
 ************************************ */

.header {
  width: 100vw;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  /*border: 1px solid red;*/
  background: url("../assets/logiLogo.svg") no-repeat;
  background-position: 22px 16px;
  background-size: 70px 42px;
}

.header.hidden {
  visibility: hidden;
}

.hamburger {
  width: 74px;
  height: 75%;
  align-self: baseline;
  border: none;
  outline: none;
  background: url("../assets/hamburgerMobile.svg") no-repeat;
  background-position: center center;
}

.close-button {
  width: 54px;
  height: 60px;
  border: none;
  outline: none;
  background: url("../assets/close.svg") no-repeat;
  background-position: center center;
}

.close-button.dark {
  background: url("../assets/closeDark.svg") no-repeat;
  background-position: center center;
}

/*  ************************************
 *      Checklist
 ************************************ */

input[type="checkbox"] {
  margin-right: 0px;
  height: 36px;
  width: 36px;
  min-width: 36px;
  appearance: none;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url("../assets/checkmarkBorder.svg");
  /*border: 1px solid red;*/
  border: none;
  background-size: 50% 50%;
  background-position: center;
  -webkit-tap-highlight-color: transparent;
}

input[type="checkbox"]:checked {
  background-image: url("../assets/checkmark.svg");
}

.step input {
  opacity: 1;
}

.checklist-section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin: 0px 14px 0px 2px;
  padding-bottom: 0px;
}

.checklist-section-header .label {
  font-size: 16px;
}

.option-label {
  border: 2px solid #dfdfdf;
  background: #f2f2f2;
  color: #7d7e7f;
  border-radius: 2px;
  margin-right: 2px;
  margin-left: 2px;
  padding: 2px 6px 2px 6px;
}

.checklist, .step {
  /*border: 1px solid red;*/
  background: white;
  /*background: transparent;*/
  border-radius: 12px;
  margin-bottom: 16px;
}

.checklist {
  display: none;
}

.checklist.visible {
  display: block;
}

.checklist > header, .step > header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 22px 0px 14px;
  border-bottom: 1px solid #00000020;
  /*height: 54px;*/
}

.checklist > header {
  align-items: baseline;
}

.checklist > header .label, .step > header .label {
  font-size: 18px;
  line-height: 1.5em;
}

.checklist > header .label-warning, .step > header .label-warning {
  font-size: 18px;
  line-height: 1.5em;
  color: red;
}

.step > header {
  align-items: center;
  margin: 0px 16px 0px 18px;
}

.step > header input, .select-options input {
  margin-right: -12px; /* the input is bigger than it appears, so we shift it to make it look aligned */
}

.checklist .in-box {
  opacity: 0.4;
  font-family: BrownPro-Regular;
  font-size: 16px;
  color: #2F3132;
  letter-spacing: 0;
  text-align: right;
  /*line-height: 20px;*/
}

.checklist ul, ul.select-options {
  list-style: none;
  padding-inline-start: 9px;
  padding-inline-end: 3px;
  margin-top: 0;
  margin-bottom: 0;
}

ul.select-options {
  padding-inline-start: 0px;
  padding-inline-end: 8px;
  width: 100%;
}

.checklist li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /*opacity: 50%;*/
  border-top: 1px solid #00000020;
  min-height: 66px;
}

.select-options li {
  display: flex;
  flex-direction: column;
  color: #7d7e7f;
  font-weight: bold;
}

.select-options li .option-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /*opacity: 50%;*/
  border-top: 1px solid #00000020;
  min-height: 66px;
}

.checklist input, .step input {
  height: 50px;
  width: 50px;
  min-width: 50px;
  opacity: 0.4;
}

.checklist input:checked {
  opacity: 1;
}

.tool-image {
  height: 50px;
  width: 50px;
  object-fit: scale-down;
  /*border: 1px solid red;*/
  min-width: 50px;
}

.checklist .item-name {
  margin-left: 6px;
  font-family: BrownPro-Regular;
  font-size: 16px;
  color: #2F3132;
  letter-spacing: 0;
  flex-basis: 70%;
}

.select-options .item-name {
  opacity: 0.8;
  font-family: BrownPro-Regular;
  font-weight: normal;
  font-size: 16px;
  color: #2F3132;
  letter-spacing: 0;
  line-height: 20px;
}

.item-name .option-label {
  line-height: 32px;
}

/*  ************************************
 *      Landing screen
 ************************************ */

.landing-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.landing-screen .product-name {
  font-family: BrownPro-Bold;
  font-size: 18px;
  color: #2F3132;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 10px;
  line-height: 0em;
  text-transform: uppercase;
}

.landing-screen .label {
  font-family: BrownPro-Bold;
  font-size: 46px;
  color: #2F3132;
  letter-spacing: -1.42px;
  text-align: center;
  margin-top: 5px;
  line-height: 1em;
  text-transform: uppercase;
}

.landing-screen .button-container {
  position: static;
  bottom: 0;
}

.landing-screen button {
  margin-bottom: 5%;
}

/*  ************************************
 *      Tool list screen
 ************************************ */


.overview, .tool-list {
  /*background: url("../assets/guides/guidePhoneTOC.png") no-repeat;*/
  /*background-size: 100% 100%;*/
  font-family: BrownPro-Bold;
  font-size: 18px;
  color: #2F3132;
  letter-spacing: 0;
  line-height: 20px;
}

.tool-list {
  pointer-events: all;
}

.tool-list-btn-container .btn-start-over {
  margin-bottom: 12px;
}
.tool-list-btn-container {
  width: calc(100% - 22px);
  margin: 48px 11px 0 11px;
}

/*  ************************************
 *      Overview screen
 ************************************ */

.overview {
  pointer-events: none;
}

.overview-container {
  /*margin-top: 120px;*/
  margin-left: 20px;
}

.overview li {
  font-family: BrownPro-Bold;
  font-size: 18px;
  color: #2F3132;
  letter-spacing: 0;
  margin-bottom: 1em;
  line-height: 1em;
}

.overview li div, .menu-overlay li div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*align-items: center;*/
}

.overview li {
  width: calc(100% - 20px);
}

.overview li.checked, .menu-container li.checked {
  opacity: 0.6;
}

.overview .btn-continue, .tool-list-btn-container .btn-continue, .tool-list-container .btn-continue {
  font-size: 16px;
  margin-bottom: 12px;
}

.overview .button-container {
  bottom: -58px;
}

.overview input, .menu-container input {
  /*position: fixed;*/
  /*right: 46px;*/
  /*margin-top: -5px;*/
  height: 30px;
  width: 30px;
  min-width: 30px;
  opacity: 0;
  background-position-y: top;
  margin-bottom: -10px;
}

.overview li.checked input, .menu-container li.checked input {
  opacity: 1;
}

/*  ************************************
 *      Menu and Expanded Info overlay
 ************************************ */

.overlay-holder {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  /*border: 1px solid red;*/
}

.menu-overlay, .expanded-info {
  width: 100%;
  height: 100%;
  color: white;
  /*background: url("../assets/guides/guidePhoneMenu.png") no-repeat;*/
  /*background-size: 100%;*/
  background: #2f3132;
  position: absolute;
  top: 0;
  left: 0vw;
  transition: transform 0.3s;
  pointer-events: auto;
  /*border: 1px solid blue;*/
}

.menu-overlay {
  transform: translateX(100vw);
  overflow-y: auto;
}

.expanded-info {
  /*background: url("../assets/guides/guidePhoneOverlay.png") no-repeat;*/
  /*background-size: 100% 100%;*/
  background: #FFFFFFF3;
  transform: translateX(-100vw);
}

.menu-overlay.visible, .expanded-info.visible {
  transform: translateX(0vw);
}

.menu-overlay > header, .expanded-info > header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.menu-overlay > main {
  padding-left: 38px;
  padding-top: 0px;
}

.menu-container input:checked {
  background-image: url("../assets/checkmarkLight.svg");
  height: 36px;
  width: 36px;
  min-width: 36px;
}

.menu-overlay main, .expanded-info main {
  overscroll-behavior: contain;
  overflow-y: auto;
}

.menu-overlay .menu-heading {
  margin-block-start: 36px;
  margin-block-end: 6px;
  font-family: BrownPro-Regular;
  font-size: 14px;
  color: rgba(255,255,255,0.60);
  letter-spacing: 0;
  line-height: 20px;
  margin-right: 20px;
  border-bottom: 1px solid rgba(255,255,255,0.30);
}

.menu-overlay .menu-heading:first-of-type {
  margin-block-start: 6px;
}

.expanded-info main::-webkit-scrollbar {
  display: none;
}

.menu-overlay ul, .menu-overlay ol {
  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  /*border: 1px solid red;*/
}

.menu-overlay ol {
  margin-top: 0px;
}

.menu-overlay ul {
  list-style-type: none;
  /*padding-inline-start: 36px;*/
}

.menu-overlay ul li {
  background: url('../assets/bullet.svg') no-repeat left center;
  margin-left: 0px;
  padding-left: 30px;
  width: calc(100% - 10px);
}

.menu-overlay ul li.compatibility {
  background-image: url('../assets/bullet_compatibility.svg');
}

.menu-overlay ul li.tools {
  background-image: url('../assets/bullet_drill.svg');
}

.menu-overlay ul li.pro {
  background-image: url('../assets/bullet_pro.svg');
}

.menu-overlay ul li.support {
  background-image: url('../assets/bullet_faq.svg');
}

.menu-overlay ol li {
  width: calc(100% - 30px);
}

.menu-overlay ul li:first-child {
  margin-top: 0px;
}

.menu-overlay p {
  font-family: BrownPro-Bold;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 20px;
}

.menu-overlay li {
  font-family: BrownPro-Bold;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 1.5em;
  margin-bottom: 12px;
  margin-left: 20px;
  padding-left: 10px;
  cursor: pointer;
  /*border: 1px solid blue;*/
}

.menu-overlay .links {
  font-family: BrownPro-Bold;
  font-size: 23px;
  color: #FFFFFF;
  line-height: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0000009F;
  justify-content: center;
  align-items: center;
  display: none;
}

.modal-overlay.visible {
  display: flex;
}

.modal-overlay .popup {
  background: white;
  width: 720px;
  height: 274px;
  border-radius: 8px;
  padding: 20px 20px 20px 42px;
}

.modal-overlay .close-button.dark {
  background: url("../assets/closeDarkLarge.svg") no-repeat;
  background-position: center center;
}

.modal-overlay header {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-overlay .menu-heading {
  font-family: BrownPro-Bold;
  font-size: 22px;
  color: #000000;
  line-height: 22px;
}

.modal-overlay .help-text {
  opacity: 0.8;
  font-family: BrownPro-Regular;
  font-size: 18px;
  color: #2F3132;
  letter-spacing: -0.19px;
  line-height: 22px;
  margin-right: 80px;
}

.modal-overlay .button-container {
  flex-direction: row;
  justify-content: space-between;
  margin: 46px 0 0 42%;
}

.modal-overlay .btn-continue, .modal-overlay .btn-start-over {
  width: 45%;
  font-size: 18px;
}

.expanded-info .label {
  margin-block-start: 0;
}

.expanded-info main {
  margin: 10px 30px 20px 30px;
  height: calc(100% - 60px);
}

.expanded-info .content {
  margin-block-start: 0;
  flex-basis: 80%;
}

.illustration {
  max-width: 100%;
  object-fit: contain;
  object-position: center center;
}

.expanded-info .illustration {
  margin-top: 20px;
}

.expanded-info .btn-more {
  min-height: 42px;
}

.btn-next-step {
  font-size: 16px;
  width: 94%;
  height: 44px;
  margin: 6px 3% 6px 3%;
}

/*  ************************************
 *      Sections / Steps
 ************************************ */

.section-container {
  width: calc(100vw - 22px);
  margin-left: 11px;
  margin-right: 11px;
  margin-top: 32px;
  /*border: 1px solid blue;*/
}

.section-container > header, .tool-list-container > header {
  /*border: 1px solid red;*/
  margin-left: 17px;
  margin-bottom: 22px;
}

.section-container > main {
  /*padding-top: 20px;*/
}

.section-number {
  opacity: 0.8;
  font-family: BrownPro-Regular;
  font-size: 16px;
  color: #2F3132;
  letter-spacing: 0px;
  line-height: 20px;
  /*border: 1px solid green;*/
  margin-top: 0px;
  margin-block-end: 12px;
}

.section-title {
  font-family: BrownPro-Bold;
  font-size: 46px;
  color: #2F3132;
  letter-spacing: -1.5px;
  line-height: 46px;
  text-transform: uppercase;
  margin-top: 0px;
  margin-left: 0px;
  margin-block-end: 6px;
}

.step main, .expanded-info main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  /*border: 1px solid red;*/
}

.step input[type="checkbox"] {
  margin-block-start: 4px;
}

.step main {
  margin: 20px 18px 0px 18px;
}

.step main.select-main {
  margin: 20px 8px 0px 18px;
}

.img-holder {
  display: flex;
  justify-content: center;
}

.content {
  /*display: flex;*/
  /*flex-direction: column;*/
  opacity: 0.8;
  font-family: BrownPro-Regular;
  font-size: 16px;
  color: #2F3132;
  letter-spacing: 0;
  margin-bottom: 20px;
  width: 100%;
}

.step .content {
  /* Prepare the content to be clamped by -webkit-line-clamp */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-block-start: 0;
}

.truncate5 {
  /* Truncate after 5 lines */
  /* We use this to determine if something *should* be clamped */
  -webkit-line-clamp: 5;
}

.truncate3 {
  /* Truncate after 3 lines */
  /* We use this to do the actual clamping */
  -webkit-line-clamp: 3;
}

.step .content ul {
  margin-block-start: 0;
}

.step.checked header, .step.checked main, .checklist li.checked * {
  opacity: 0.2;
}

.step .content.truncate3 li {
  text-overflow: ellipsis;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  list-style-position: inside;
}

.step .img-holder {
  max-width: 80%;
  margin: 0 10% 0 10%;
}

.step .btn-more, .expanded-info .btn-more {
  background: rgba(220,253,0,0.10);
}
