/*  ************************************
 *      Tablet
 ************************************ */

/* Standard tablet */
@media all and (min-device-width : 768px) and (max-device-width : 1024px) {
    .column-left {
        width: 350px;
        background-size: 522px 632px;
        background-position-x: right;
    }

    .main-screen {
        margin-left: 26px;
        height: calc(100% - 160px);
    }

    .main-screen .column-right {
        margin-right: 16px;
    }

    .tool-list {
        height: calc(100% - 104px);
    }

    .checklist li {
        min-width: 240px;
    }

    .overview .column-left {
        display: none;
    }

    .overview .column-right {
        margin-left: calc(50% - 240px);
        margin-top: 70px;
    }

    .overview-container {
        margin-top: 0px !important;
        margin-left: 49px;
    }

    .overview .button-container {
        margin: 0 22px 0 50px;
    }

    .header {
        margin-bottom: 60px;
    }

    .checklist-section-header {
        padding-top: 16px;
    }

    .section-title {
        font-family: BrownPro-Bold;
        color: #2F3132;
        letter-spacing: -1px;
        line-height: 42px;
    }

    .tool-list-container .label {
        font-size: 20px;
        line-height: 0.8em;
    }
}


/* iPad Pro */
@media all and (min-device-width : 1024px) and (max-device-width : 1024px) {
    .column-left {
        width: 460px;
    }
}

/*  ************************************
 *      Desktop
 ************************************ */
@media all and (min-device-width : 1025px) {
    .landing-screen {
        min-width: 1140px;
    }

    .main-screen {
        margin-left: 252px;
        height: calc(100% - 80px);
    }

    .column-left {
        width: 680px;
        background-size: contain;
        background-position-x: center;
    }

    .main-screen .column-right {
        margin-right: 20%;
    }

    .tool-list {
        height: calc(100% - 64px);
    }

    .checklist li {
        min-width: 540px;
    }
}

/*  ************************************
 *      All tablets and desktop (i.e., anything but mobile)
 ************************************ */

@media all and (min-device-width : 768px) {
    .app-holder {
        height: 100%;
    }

    .header {
        background-position: 14px 14px;
        background-size: 100px 60px;
    }

    .landing-screen main, .tool-list-container main, .overview main, .main-screen main {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        /*border: 1px solid green;*/
    }

    .column-left {
        height: 680px;
        background-image: url("../assets/doorbell@3x.png");
        background-repeat: no-repeat;
        background-position-y: calc(50% + 70px);
    }

    .column-right {
        width: 450px;
        height: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-block-start: 80px;
        /*border: 2px solid purple;*/
    }

    .button-container {
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: static;
        bottom: auto;
        width: auto;
    }

    .landing-screen .product-name {
        font-size: 24px;
        line-height: 0em;
        margin-top: 120px;
        marging-bottom: 5px;
   }

    .landing-screen .label {
        font-size: 60px;
        line-height: 1em;
        margin-top: 20px;
        margin-bottom: 100px;
     }

    .btn-primary-landing {
        width: 100%;
        margin-right: 50px;
        margin-left: 50px;
    }

    .header.hidden {
        visibility: visible; /* On Desktop, the Logi logo is always present */
    }

    .hamburger {
        width: 104px;
        height: 100%;
        outline: none;
        background: url("../assets/hamburgerDesktop.svg") no-repeat;
        background-position: center center;
        background-size: 24px;
    }

    .hamburger:hover {
        background-image: url("../assets/hamburgerDesktopOver.svg");
    }

    .header.hidden .hamburger {
        visibility: hidden; /* On Desktop, the hamburger menu is hidden on the Landing screen */
    }

    .tool-list {
        overflow-y: auto;
        padding-right: 8px;
        margin-bottom: 12px;
    }

    .tool-list .checklist.visible {
        background: white;
        margin: 0 0px 18px 0px;
        border-radius: 6px;
        padding: 0 20px 0 20px;
    }

    .tool-list .checklist.visible:last-child {
        margin-bottom: 0;
    }

    .overview {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .overview .column-right {
        align-items: flex-start;
        padding-left: 20px;
        width: 600px;
    }

    .overview li.checked {
        opacity: 0.2;
    }

    .main-screen.tool-list-container .column-left {
        display: none;
    }

    .main-screen.tool-list-container .column-right {
        background: none;
        padding: 0;
        margin-left: 0;
        align-items: inherit;
    }

    .overview-container {
        margin-top: 28px;
        /*height: 100%;*/
        width: 70%;
    }

    .overview-container ol {
        padding-inline-start: 25px;
    }

    .overview li {
        font-family: BrownPro-Bold;
        font-size: 22px;
        color: #2F3132;
        margin-bottom: 0.7em;
        letter-spacing: -0.55px;
        line-height: 42px;
        width: 98%;
        /*border-top: 1px solid red;*/
    }

    .overview input {
        height: 50px;
        width: 50px;
        min-width: 50px;
        margin-top: 8px;
        margin-bottom: -20px;
        opacity: 0;
    }

    .menu-container input {
        height: 36px;
        width: 36px;
        min-width: 36px;
        opacity: 0;
    }

    .overview .button-container, .tool-list-container .button-container {
        flex-direction: row;
        width: 60%;
    }

    .tool-list-container .button-container {
        width: 40%;
        margin-left: calc(60% - 15px);
    }

    .menu-overlay {
        width: 400px;
    }

    .menu-overlay.visible {
        transform: translateX(calc(100vw - 400px));
    }

    .App.menu-visible > .main-screen {
        transform: none;
    }

    .main-screen main {
        height: calc(100% - 88px);
    }

    .main-screen .column-left {
        background: transparent;
        width: 315px;
        min-width: 315px;
        height: 100%;
        padding-right: 10px;
        /*background: red;*/
        /*border: 1px solid red;*/
        overflow-x: hidden;
        overflow-y: auto;
    }

    .main-screen .column-right {
        width: inherit;
        height: 100%;
        margin-left: 8px;
        padding: 25px 16px 4px 5px;
        background: white;
    }

    .main-screen.compatibility-checklist .column-right {
        padding: 0;
        /*background: transparent;*/
    }

    .expanded-info, .checklist {
        display: none;
    }

    .expanded-info.visible, .checklist.visible {
        display: block;
    }

    .checklist.visible {
        width: 100%;
    }

    .expanded-info, .expanded-info.visible, .checklist, .checklist.visible {
        position: static;
        transform: none;
        background: transparent;
        /*overflow-y: visible;*/
        overflow-y: auto;
    }

    .main-screen.compatibility-checklist .expanded-info.visible {
        background: white;
        padding: 25px 16px 4px 5px;
    }

    .main-screen.compatibility-checklist .button-footer {
        flex-direction: column;
        flex-flow: column-reverse;
        justify-content: flex-start;
        width: 50%;
    }

    .compatibility-checklist .btn-primary, .compatibility-checklist .btn-secondary, .compatibility-checklist .btn-tertiary {
        margin: 6px 4px 12px 0px;
    }

    .expanded-info main, .checklist main {
        display: block;
        width: auto;
        overflow-y: visible;
        margin: 10px 30px 20px 30px;
    }

    .expanded-info .label, .checklist main > .label {
        font-size: 22px;
        padding-bottom: 20px;
        border-bottom: 2px solid #00000010;
        margin-block-start: 0;
    }

    .expanded-info .label {
        margin-bottom: 22px;
    }

    .checklist main > .label {
        margin-bottom: 3px;
    }

    .section-container {
        width: inherit;
        height: 100%;
        margin: 0;
    }

    .section-container > header, .tool-list-container > header {
        margin-left: 0px;
    }

    .section-number {
        font-size: 16px;
        margin-block-end: 0px;
    }

    .section-title {
        font-size: 36px;
    }

    .step {
        border-radius: 6px;
        border-width: 2px;
        border-style: solid;
        border-color: rgba(255,255,255,0.1);
        margin-bottom: 9px;
        min-height: 58px;
    }

    .step.focused, .step:hover {
        background: #fdffe7;
        border-color: #adc51d66;
    }

    .step .label {
        font-family: BrownPro-Bold;
        font-size: 16px;
        color: #2F3132;
        letter-spacing: 0;
        line-height: 1em;
        pointer-events: none;
    }

    .step.tool-icon input {
        background-image: url("../assets/illustrations/drill.svg");
        cursor: default;
    }

    .step input {
        height: 50px;
        width: 50px;
        min-width: 50px;
        cursor: pointer;
    }

    .step > header {
        margin: 0px 0px 0px 24px;
        border-bottom: none;
    }

    .step > header input {
        margin-right: 0;
    }

    .checklist-section-header .in-box {
        font-size: 12px;
    }

    .checklist ul {
        padding-inline-start: 0px;
        padding-inline-end: 0px;
    }

    .checklist li {
        padding-left: 10px;
        min-height: 54px;
    }

    .checklist li:last-child {
        border-bottom: 1px solid #00000020;
    }

    .tool-image {
        height: 30px;
        width: 30px;
        min-width: 30px;
    }

    .checklist .item-name {
        font-size: 14px;
        flex-basis: 83%;
        letter-spacing: 0;
        font-family: BrownPro-Regular;
        color: #2F3132;
        line-height: 20px;
    }

    .checklist input {
        height: 48px;
        width: 48px;
        min-width: 48px;
    }

    .btn-next-step {
        color: #2F3132;
        border: none;
        outline: none;
        width: 136px;
        background: url("../assets/caret.svg") no-repeat;
        background-position: 6px calc(50% - 2px);
        margin-left: calc(100% - 136px);
    }

    .btn-next-step-lang-fr {
        width: 180px;
        margin-left: calc(100% - 180px);
    }

}

/* see http://stephen.io/mediaqueries/ */

/*@media all and (max-width: 1024px) {*/
    /*.landing-screen main {*/
        /*background: pink;*/
    /*}*/
/*}*/

/*

/* thinscreen */
/*@media (min-aspect-ratio: 1/1000) {*/

    /*!* standard *!*/
    /*@media (min-aspect-ratio: 1230/1000) {*/



        /*!* widescreen *!*/
        /*@media (min-aspect-ratio: 1640/1000) {*/
            /**/
        /*}*/
